
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/[...pathTerms]",
      function () {
        return require("private-next-pages/[...pathTerms].tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/[...pathTerms]"])
      });
    }
  